<template>
  <v-container fluid class="fill-height hp-body-bg">
    <no-user-navbar :showBackButton="false"/>
    <v-row class="my-auto">
      <v-col cols="12" md="8" lg="6" xl="4" class="mx-auto">
        <v-card class="rounded-card-with-shadow mx-auto">
          <!-- <v-toolbar color="primary" elevation="0" dark>
            <logoSvg />
          </v-toolbar> -->
          <v-card-title>

            <h4 class="display-1 font-weight-bold primary--text text-center mt-6">
              {{ $t("Thank You for Submitting Your Request!") }}
            </h4>
          </v-card-title>
          <v-card-text class="pa-8">
            <p class="body-1">
              {{ $t("Dear") }} {{ getUserName(me) + ',' }}
              {{
                $t(
                  "We have received your request to access the Behavioral Health Program with TalkTime. Our team is currently reviewing your coverage, and we will promptly notify you within the next 24 hours regarding whether your plan includes this program."
                )
              }}
              </p>
            <p class="body-1">
              {{
                $t(
                  "If you have any questions or need further assistance, please don't hesitate to reach out to our customer support team. We are here to help!"
                )
              }}
            </p>
            <p class="body-1">
              {{
                $t(
                  "Thank you for choosing Talktime to be a part of your behavioral health journey. We look forward to providing you with the best care possible."
                )
              }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <NoUserFooter />
  </v-container>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";
import NoUserNavbar from "@/components/layout/NoUserNavbar.vue";
import NoUserFooter from "@/components/layout/NoUserFooter.vue";

export default {
  name: "SubmittedByClient",
  components: { NoUserNavbar, NoUserFooter },
  mixins: [UserMixin],

  data: () => {
    return {
      loading: false,
    };
  },

  mounted() {
    this.awaitPromiseCallback({
      key: "getMe",
      cb: () => {
        if (this.isClient && this.myInsurance && this.myInsurance.status !== 'submitted') {
          if (!this.myTherapist) {
            if(this.myInsurance && this.myInsurance.status === 'declined') {
              this.$router.push({ name: "NotConfirmedInsurance" });
            }
            else if(this.myInsurance && this.myInsurance.status === 'approved') {
              this.$router.push({ name: "ConfirmedInsurance" });
            } else {
              this.$router.push({ name: "TherapistSelection" });
            }
          }
        }
      },
    });
  }
};
</script>

<style scoped>
.theme--light .hp-body-bg {
  background-position: top left, top right;
  /* background-attachment: fixed; */
  background-image: url("../../../assets/background-gradient-left.png"),
  url("../../../assets/background-gradient-right.png");
  }
</style>
